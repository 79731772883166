.Home {
  color: black;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(
    to bottom,
    #FF0000 0%,
    #FF0000 16.67%,
    #FF8E00 16.67%,
    #FF8E00 33.33%,
    #FFEE00 33.33%,
    #FFEE00 50%,
    #008E00 50%,
    #008E00 66.67%,
    #00C0FF 66.67%,
    #00C0FF 83.33%,
    #8A2BE2 83.33%,
    #8A2BE2 100%
  );
  background-attachment: local;
  background-size: 100% 100%;
  font-family: 'Bubblegum';
}

.HomeContentDescription {
  padding-left: 46%;
}

.HomeContentImage {
  position: absolute;
  bottom: 0;
  left: 6%;
  width: 35vw;
}

.HomeContentImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.HomeContentDescription {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.HomeContentDescription {
  display: flex;
  flex-direction: column;
  gap:2rem;
}

.HomeContentText {
  padding-top: 10vh;
}

/* .HomeContentTitleImage {
  position: absolute;
  top: 17%;
  left: 42%;
  width: 50rem;
} */

.HomeContentTextTitle {
  font-size: 17vw;
  /* font-weight: bolder; */
  color: rgb(255, 99, 37);
  font-family: Bubblegum;
}

.HomeContentTextText {
  font-size: 2.7vw;
  padding-left: 0.7vw;
}
.HomeContentTextTextItalic {
  padding-left: 1vw;
  font-size: 1.3vw;
  font-style: italic;
}

@media screen and (orientation: portrait) {
  .HomeContentDescription {
    padding-left: 0%;
  }

  .HomeContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .HomeContentImage {
    left: 15%;
    width: 55vw;
  }

  .HomeContentTextTitle {
    font-size: 14vh;
  }

  .HomeContentTextText {
    font-size: 2.8vh;
  }

  .HomeContentTextTextItalic {
    font-size: 1.4vh;
  }
  
}



/* @media (max-width: 800px) {
  .HomeContentDescription {
    padding-left: 0%;
  }

  .HomeContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .HomeContentImage {
    left: 15%;
    width: 35rem;
  }
}

@media (max-width: 600px) {
  .HomeContentImage {
    left: 15%;
    width: 35rem;
  }

  .title-rainbow-text {
    font-size: 14rem;
  }
} */
