.About {
  color: black;
  width: 100%;
  /* min-height: 100vh; */
  overflow: hidden;
  background: linear-gradient(
    to bottom,
    #FF0000 0%,
    #FF0000 16.67%,
    #FF8E00 16.67%,
    #FF8E00 33.33%,
    #FFEE00 33.33%,
    #FFEE00 50%,
    #008E00 50%,
    #008E00 66.67%,
    #00C0FF 66.67%,
    #00C0FF 83.33%,
    #8A2BE2 83.33%,
    #8A2BE2 100%
  );
  background-attachment: local;
  background-size: 100% 100%;
  font-family: 'Bubblegum';
  /* position: relative; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;

}

.AboutTeam {
  /* position: absolute; */
  /* bottom: 0; */
  /* background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px); */
  padding-bottom: 1.3rem;
  /* padding-top: 1.5rem; */
  /* border: 0.2rem solid black; */
  /* border-radius: 2rem;
  border-bottom: none; */
  /* width: 80%; */
  width: 100vw;
}

.AboutTeamContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
}

.AboutTeamTitle {
  font-size: 3em;
}

.AboutTeamImages {
  display: flex;
  /* justify-content: space-around; */
  gap: 6em;
}

.avatarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
}

.avatar {
  border: 0.3em solid black;
  border-radius: 100%;
  width: 11.5em;
}

.avatarName {
  font-size: 1.6em;
}

.AboutWokiContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AboutWoki {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0.23em solid black;
  border-radius: 2rem;
  padding: 3rem 4rem;
  width: 67vw;
  margin-top: 2rem;
  text-align: center;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(150px);
}

.AboutWokiTitle {
  font-size: 5rem;
}

.AboutWokiText {
  /* border-radius: 2rem;
  background-color: black;
  padding: 2rem; */
  padding-top: 2rem;
  font-size: 2rem;
  font-family: Quatsch;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  
}

.AboutWokiImage {
  width: 60em;
}

@media screen and (orientation: portrait) {
  .AboutTeamImages {
    gap: 2em;
  }
  .avatar {
    width: 12.7em;
  }
  .avatarName {
    font-size: 1.8em;
  }
}

@media (max-width: 1800px) {

  .AboutWoki {
    padding: 3rem 2rem;
  }
  .AboutWokiTitle {
    font-size: 3rem;
  }

  .AboutWokiText {
    font-size: 1.8rem;
  }

  .AboutTeamTitle {
    font-size: 2.5em;
  }
  .AboutTeamImages {
    gap: 2em;
  }
  .avatar {
    width: 10em;
  }
  .avatarName {
    font-size: 1.7em;
  }
  .AboutTeamContainer {
    gap: 1rem;
  }
}