.Roadmap {
  color: black;
  /* height: 100vh; */
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    to bottom,
    #FF0000 0%,
    #FF0000 16.67%,
    #FF8E00 16.67%,
    #FF8E00 33.33%,
    #FFEE00 33.33%,
    #FFEE00 50%,
    #008E00 50%,
    #008E00 66.67%,
    #00C0FF 66.67%,
    #00C0FF 83.33%,
    #8A2BE2 83.33%,
    #8A2BE2 100%
  );
  background-attachment: local;
  background-size: 100% 100%;
  font-family: 'Bubblegum';
  padding-top: 3rem;
  padding-bottom: 6rem;
}

.RoadmapContentContainer {
  max-width: 100%;
  max-height: 100vh;
  display: flex;
}

.RoadmapTitle {
  padding-top: 1.27em;
  font-size: 8rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5em;
}

.ImageWrapper {
  position: relative;
  display: inline-block;
  max-width: 100%;
  max-height: 100vh;
}

.roadmapimg {
  max-height: 98.5vh;
  max-width: 100%;
  display: block;
}
.clickdots {
  width: 2em;
}

.popup-trigger {
  position: absolute;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.popup-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  text-align: center;
  border: 0.2em solid #000000;
  border-radius: 0.5em;
  padding: 1em 0.5em;
  z-index: 2;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.5em;
  width: 13em;
  overflow: hidden;
  animation: fadeIn 0.3s ease-out;
}

.popup-content::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: rotate(30deg);
  animation: shine 1s infinite linear;
}

.popup-trigger:hover .popup-content,
.popup-trigger.active .popup-content {
  display: block;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}

@keyframes shine {
  0% {
    top: -50%;
    left: -50%;
  }
  100% {
    top: 150%;
    left: 150%;
  }
}

@media screen and (max-aspect-ratio: 5/3) {
  .RoadmapContentContainer {
    max-height: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .RoadmapTitle {
    padding-top: 0em;
    max-height: 20vh;
    flex-direction: row;
    gap: 0.5em;
  }
  .roadmapimg {
    max-height: 80vh;
    max-width: 100%;
    display: block;
  }
}


@media screen and (orientation: portrait) {
  .RoadmapContentContainer {
    max-height: none;
    width: 98.5vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .RoadmapTitle {
    padding-top: 0em;
    flex-direction: column;
  }

  .clickdots {
    visibility: hidden;
    width: 0%;
  }

  .ImageWrapper {
    max-height: none;
    width: 100%;
  }

  .roadmapimg {
    max-height: none;
    width: 100%;
  }

  .popup-content {
    width:9.5em;
  }
}