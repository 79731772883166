.TopLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  font-size: 2.5rem;
  font-weight: bolder;
  padding-top: 2rem;
}

.TopLinkContainer {
  text-decoration: none;
  color: black;
  perspective: 1000px;
  display: inline-block;
}

.rotate-text {
  display: inline-block;
  transition: transform 0.3s ease-out;
  transform-style: preserve-3d;
}

.TopLinkContainer:hover .rotate-text {
  transform: rotateX(360deg);
}

/* Optional: Add a subtle shadow effect during rotation */
@keyframes shadow-pulse {
  0%, 100% { text-shadow: 0 0 0 rgba(0,0,0,0); }
  50% { text-shadow: 0 10px 15px rgba(0,0,0,0.2); }
}

.TopLinkContainer:hover .rotate-text {
  animation: shadow-pulse 0.3s ease-out;
}